import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-home',
  template: `
    <div> hello from the home page <strong class="text-xl text-indigo-600"> {{url}} </strong>  </div>
  `,
  styles: ``,
  standalone: true,
  imports: [],
})
export class HomeComponent {
  url = environment.URLS.ATS_URL;
}
